import React, { useState } from 'react';

import Layout from './Layout.jsx'
import Login from './Login.jsx'

function App() {
  const [ loggedIn, setLoggedIn ] = useState(sessionStorage.getItem("jwt") ? true : false);

  if (loggedIn) {
    return <Layout />;
  } else {
    return <Login setLoggedIn={setLoggedIn}/>
  }
}

export default App;
