import './Login.css';

import React, { useState } from 'react'
import { Box, Button, Container, Link, Grid, Input, Typography } from '@mui/material';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

import Logo from './icons/logo.svg'
import { CLIENT_ID, USER_POOL_ID } from './constants.js';
import { useAwsLambdaContext } from './context/AwsLambda';
import { logout } from './utils';

const Login = ({ setLoggedIn }) => {
  const [ values, setValues ] = useState({})
  const { initClient } = useAwsLambdaContext()

  const handleChange = (evt) => {
    let vals = { ...values }
    vals[evt.target.name] = evt.target.value
    setValues(vals)
  }

  const login = async (e) => {
    e.preventDefault();

    const authenticationDetails = new AuthenticationDetails({
      Username : values.username,
      Password : values.password,
    });
    const poolData = {
      UserPoolId: USER_POOL_ID,
      ClientId: CLIENT_ID
    };
    const userData = {
      Username: values.username,
      Pool: new CognitoUserPool(poolData)
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        sessionStorage.setItem("jwt", result.getIdToken().jwtToken)
        initClient()
        setLoggedIn(true)
      },

      mfaSetup: function() {
        cognitoUser.associateSoftwareToken(this);
      },

      totpRequired: function() {
        var challengeAnswer = prompt('Please input the OTP code.', '');
        cognitoUser.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
      },

      onFailure: function(err) {
        alert(err);
        logout();
      },
    });
  }

  return (
    <Container className="login-page" maxWidth="xl">
      <Grid
        container
        gap={3}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 6 }}
      >
        <Grid item xs={12} sx={{ mb: 6 }}>
          <img src={Logo} width={200} alt="" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h1'>EventOr CMS</Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={login}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Input
                  required
                  name="username"
                  type="text"
                  placeholder="Username or Email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  name="password"
                  type="password"
                  placeholder="Password"
                  autoComplete='current-password'
                  onChange={handleChange}
                />
                <Box sx={{ mt: .5 }}>
                  <Link
                    color='secondary'
                    variant='subtitle2'
                    href={`https://eventor-admin-site.auth.eu-west-2.amazoncognito.com/forgotPassword?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${window.location.href}`}
                  >Forgot password?</Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!values.password || !values.username}
                >Login</Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Login