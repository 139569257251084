import React from 'react'
import {
  useMediaQuery,
  AppBar,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import {
  Add as AddIcon,
  Home as HomeIcon,
  Menu as MenuIcon
} from '@mui/icons-material';
import clsx from 'clsx';

import SearchEventInput from './SearchEventInput';
import { DRAWER_WIDTH } from './constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Header = ({ onDrawerOpen, open }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        {!open && (
          <Tooltip title="Open menu" arrow>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerOpen}
              edge="start"
              size="large"
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        )}

        {isMobile
          ? <IconButton href='/'><HomeIcon /></IconButton>
          : <a href='/'>
            <Typography variant="h6" noWrap>EventOr CMS</Typography>
          </a>
        }

        <div style={{ flexGrow: 1 }} />

        <Button
          startIcon={<AddIcon />}
          href='/event/create'
          variant='contained'
          color='secondary'
          sx={{ mr: { xs: 1, md: 2 }, '& .MuiButton-startIcon': { mx: isMobile ? 0 : undefined } }}
        >{isMobile ? '' : 'New event'}</Button>

        <SearchEventInput />
      </Toolbar>
    </AppBar>

  )
}

export default Header