import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useAwsLambdaContext } from './context/AwsLambda';

const EventsStatsTable = () => {
  const { getStats, isStatsLoading } = useAwsLambdaContext()
  const [ data, setData ] = useState(null)

  const fetchData = useCallback(async () => {
    setData(null)
    const stats = await getStats()
    setData(stats)
  }, [ getStats ])

  useEffect(() => {
    fetchData()
  }, [ fetchData ])

  const cities = useMemo(() => {
    if (!data?.rows?.length) return []

    const _cities = data.rows.map(({ city }) => city)
    const uniqueCities = new Set(_cities)
    return Array.from(uniqueCities)
  }, [ data ])

  const categories = useMemo(() => {
    if (!data?.rows?.length) return []

    const _categories = data.rows.map(({ category_name }) => category_name)
    const uniqueCategories = new Set(_categories)
    return Array.from(uniqueCategories)
  }, [ data ])

  return (
    <TableContainer component={Paper} sx={{
      my: 2,
      maxHeight: '50vh',
      '& .MuiTableCell-head, & .MuiTablePagination-root': {
        backgroundColor: 'primary.dark',
        color: 'white',
      },
      '& .MuiTableCell-root:not(:last-child)': {
        borderRight: '1px solid rgba(81, 81, 81, 1)',
      },
      '& .MuiTableCell-root:first-of-type': {
        borderRightWidth: 4,
      },
    }}>
      <Table stickyHeader aria-label="table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" onClick={fetchData}>
              <IconButton aria-label="refresh" color='warning'>
                <RefreshIcon />
              </IconButton>
            </TableCell>
            {cities.map(city => (
              <TableCell key={city} align="center">
                {city}{` (${data.totalEventsForCity[city]})`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map(category => (
            <TableRow hover key={category}>
              <TableCell sx={{ maxWidth: '10em' }} key={category} align="left">
                {category}{` (${data.totalEventsForCategory[category]})`}
              </TableCell>
              {cities.map(city => (
                <TableCell key={`${category}-${city}`} align="center">
                  {data.rows.find(({ city: c, category_name: cn }) => c === city && cn === category)?.event_count || 0}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isStatsLoading && <CircularProgress />}
    </TableContainer>
  )
};

export default EventsStatsTable;
