export const DRAWER_WIDTH = 240;

export const PUBLIC_LAMBDA_API_URL = 'https://7ej3t6huqiceutu3srgm3leqdm0zpplw.lambda-url.eu-west-2.on.aws'
export const LAMBDA_NAME = 'eventor-admin-api';
export const REGION = 'eu-west-2';
export const CLIENT_ID = '1fn7bb3e259la5f6jl4bigdlpe';
export const USER_POOL_ID = 'eu-west-2_OdPS6Fsc0';
export const IDENTITY_POOL_ID = "eu-west-2:78d36efc-e8fd-4c27-90dd-71ea992b309a";

export const EVENT_IMAGE_PARAMS = [
  {
    resolution: 'low-res',
    format: 'webp',
    extension: 'webp',
    quality: .80,
    width: 350,
  },
  {
    resolution: 'high-res',
    format: 'webp',
    extension: 'webp',
    quality: .80,
    width: 1080,
  },
  {
    resolution: 'low-res',
    format: 'jpeg',
    extension: 'jpg',
    quality: .80,
    width: 350,
  },
  {
    resolution: 'high-res',
    format: 'jpeg',
    extension: 'jpg',
    quality: .80,
    width: 1080,
  },
]

export const getSectionNameFromCategoryId = categoryId => {
  switch (Number(categoryId)) {
  case 1 :
  case 2:
  case 5:
  case 7:
  case 10:
  case 11:
  case 12:
  case 13:
  case 15:
  case 20:
  case 21: {
    return 'Art & Entertainment'
  }
  case 3:
  case 4:
  case 6:
  case 8:
  case 9:
  case 14:
  case 16:
  case 17:
  case 18:
  case 19: {
    return 'Explore More'
  }
  default: {
    return ''
  }
  }
}