import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  TextField,
  IconButton,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import EventImage from './EventImage.jsx';
import EventFieldEditButton from './EventFieldEditButton.jsx';
import CategoriesSelect from './CategoriesSelect.jsx';
import { dateStringToUTCString, formatDateTime, formatISODateTime } from './utils.js';
import { useEvent } from './hooks/index';
import { useAwsLambdaContext } from './context/AwsLambda.jsx';

const ListItem = ({ sx = {}, label, value, secondaryText, secondaryAction, divider = true }) => (
  <MuiListItem sx={{ ...sx }} divider={divider} secondaryAction={secondaryAction}>
    <ListItemText
      sx={{ maxWidth: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 200,
      }}
      primary={<>
        {label}:{' '}
        <Typography
          variant='subtitle1'
          component='span'
          color='text.secondary'
          sx={{ fontWeight: 800 }}
        >{value}</Typography>
      </>}
      secondary={secondaryText}
    />
  </MuiListItem>
)

const FieldSaveButton = ({ onClick }) => (
  <Tooltip title='Save'>
    <IconButton color='success' onClick={onClick}>
      <SaveIcon />
    </IconButton>
  </Tooltip>
)

const listSx = {
  bgcolor: 'background.paper',
  py: 0,
  width: '100%',
  maxWidth: 500,
  borderRadius: 1,
  '& .MuiListItem-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  '& .MuiCollapse-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    lineHeight: '1rem',
    py: 2,
    position: 'relative',
  },
  '& .MuiCollapse-root .MuiTextField-root': {
    ml: 2,
    width: 'calc(100% - 90px)',
  },
  '& .MuiCollapse-root .MuiIconButton-root': {
    position: 'absolute',
    right: 16,
    top: '25%',
  },
}

const deletedSx = {
  textDecoration: 'line-through',
  opacity: 0.5
}

const Event = () => {
  const descriptionInputRef = useRef()
  const respondedInputRef = useRef()
  const locationInputRef = useRef()
  const dateInputRef = useRef()

  const [ dateEditMode, setDateEditMode ] = useState(false)
  const [ categoriesEditMode, setCategoriesEditMode ] = useState(false)
  const [ locationEditMode, setLocationEditMode ] = useState(false)
  const [ respondedEditMode, setRespondedEditMode ] = useState(false)
  const [ descriptionEditMode, setDescriptionEditMode ] = useState(false)
  const [ descriptionIsExpanded, setDescriptionIsExpanded ] = useState(false)
  const { id: eventId } = useParams()
  const { event, refresh, isLoading } = useEvent(eventId)
  const { updateEvent, handleNewEventImage, deleteEvent } = useAwsLambdaContext()
  const [ categoriesValue, setCategoriesValue ] = useState(event?.categories || [])

  const onDelete = async () => {
    const updated = await deleteEvent(eventId)
    if (updated) {
      refresh()
    }
  }

  const onCategoriesSave = async () => {
    const categories = categoriesValue?.map(c => c.id) || []
    //  check if changed, then save with API
    const sortedOld = event.categories?.map(c => c.id)?.sort((a, b) => a - b)
    const sortedNew = categories.sort((a, b) => a - b)
    if (JSON.stringify(sortedOld) !== JSON.stringify(sortedNew)) {
      const updated = await updateEvent({ id: eventId, updateData: { categories } })
      if (!updated) {
        return
      }
    }
    setCategoriesEditMode(false)
  }

  const onDateSave = async () => {
    const dateString = dateInputRef.current?.value
    const UTCdateString = dateStringToUTCString(dateString)
    //  check if changed, then save with API
    if (dateString !== formatISODateTime(event.date)) {
      const updated = await updateEvent({ id: eventId, updateData: { date: UTCdateString } })
      if (!updated) {
        return
      }
    }
    setDateEditMode(false)
  }

  const onDescriptionSave = async () => {
    const description = descriptionInputRef.current?.value
    //  check if changed, then save with API
    if (description !== event.description) {
      const updated = await updateEvent({ id: eventId, updateData: { description } })
      if (!updated) {
        return
      }
    }
    setDescriptionEditMode(false)
  }

  const onLocationSave = async () => {
    const location = locationInputRef.current?.value
    // check if changed, then save with API
    if (location !== event.location) {
      const updated = await updateEvent({ id: eventId, updateData: { location } })
      if (!updated) {
        return
      }
    }
    setLocationEditMode(false)
  }

  const onRespondedSave = async () => {
    const responded = respondedInputRef.current?.value
    //  check if changed, then save with API
    if (responded !== event.responded) {
      const updated = await updateEvent({ id: eventId, updateData: { responded } })
      if (!updated) {
        return
      }
    }
    setRespondedEditMode(false)
  }

  const onImageUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const updated = await handleNewEventImage(event.id, file)

      // * If the image was updated, refresh the event data to get new image
      if (updated) {
        refresh()
      }
    }
  }

  return (
    <Container sx={event?.deleted ? deletedSx : {} }>
      {!!event?.deleted &&
        <Chip label='Deleted' color='error' variant='filled' sx={{ position: 'fixed', zIndex: 2 }} />
      }

      {isLoading
        ? <CircularProgress sx={{ m: '25vh auto', display: 'block' }} />
        : (<>
          <Typography variant='h1' sx={{ textAlign: 'center' }}>Event #{eventId}</Typography>
          <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
            <Button variant='outlined' startIcon={<RefreshIcon />} color='warning' onClick={refresh}>
              Refresh
            </Button>
            <Button
              disabled={!!event.deleted}
              variant='outlined'
              startIcon={<DeleteIcon />}
              color='error'
              onClick={onDelete}
            >
              Delete
            </Button>
          </Box>

          <Box sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
          }}>
            <EventImage event={event} onUpload={onImageUpload} />

            <List sx={listSx}>
              <ListItem
                label='ID'
                value={event.id}
                secondaryText={<a target='_blank' rel="noreferrer" href={`https://eventor.com.cy/event/${event.id}`}>View on eventor.com.cy</a>}
              />

              <ListItem
                label='Title'
                value={event.title}
              />

              <ListItem
                divider={!descriptionIsExpanded}
                label='Description'
                value={event.description?.slice(0, 50)}
                sx={{ pr: 12 }}
                secondaryAction={<>
                  {event.description?.length > 50 && (
                    <Tooltip title={`${descriptionIsExpanded ? 'Hide' : 'Show'} full description`}>
                      <IconButton onClick={() => setDescriptionIsExpanded(!descriptionIsExpanded)}>
                        {descriptionIsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                  <EventFieldEditButton
                    fieldName='description'
                    editMode={descriptionEditMode}
                    setEditMode={setDescriptionEditMode}
                  />
                </>}
              />
              <Collapse
                unmountOnExit
                sx={{ pl: 2 }}
                in={descriptionIsExpanded || descriptionEditMode}
                timeout="auto"
              >
                <pre style={{ textWrap: 'wrap', margin: 0 }}>
                  {descriptionEditMode ? (
                    <>
                      <TextField
                        fullWidth
                        multiline
                        inputRef={descriptionInputRef}
                        placeholder='Event description'
                        defaultValue={event.description}
                        type='text'
                        color='secondary'
                      />
                      <FieldSaveButton onClick={onDescriptionSave} />
                    </>
                  ) : (
                    <Typography variant='subtitle1' component='span' color='text.secondary'>
                      {event.description}
                    </Typography>
                  )}
                </pre>
              </Collapse>


              <ListItem
                label='Local date'
                value={formatDateTime(event.date)}
                secondaryText={`UTC date: ${event.date}`}
                secondaryAction={
                  <EventFieldEditButton
                    fieldName='date'
                    editMode={dateEditMode}
                    setEditMode={setDateEditMode}
                  />
                }
              />
              <Collapse
                unmountOnExit
                in={dateEditMode}
                timeout="auto"
              >
                <TextField
                  fullWidth
                  inputRef={dateInputRef}
                  placeholder='Event local date'
                  defaultValue={formatISODateTime(event.date)}
                  type='datetime-local'
                  color='secondary'
                />
                <FieldSaveButton onClick={onDateSave} />
              </Collapse>

              <ListItem
                label='City'
                value={event.city}
              />

              <ListItem
                label='Organiser'
                value={event.organiser_name}
              />

              <ListItem
                label='Categories'
                value={categoriesEditMode
                  ? <CategoriesSelect
                    sx={{ width: 'calc(100% - 92px)', display: 'inline-block' }}
                    value={categoriesValue}
                    setValue={setCategoriesValue}
                    defaultValue={event?.categories}
                  />
                  : event?.categories?.map(({ id, name }) => (
                    <Chip key={`category-${id}`} label={name} sx={{ mr: 0.5 }} size='small' color='secondary' />
                  ))
                }
                secondaryAction={
                  <>
                    {categoriesEditMode && <FieldSaveButton onClick={onCategoriesSave} />}
                    <EventFieldEditButton
                      fieldName='categories'
                      editMode={categoriesEditMode}
                      setEditMode={setCategoriesEditMode}
                    />
                  </>
                }
              />

              <ListItem
                divider={!locationEditMode}
                label='Location'
                value={event?.location}
                secondaryText={event?.location
                  ? <a target='_blank' rel="noreferrer" href={`https://maps.google.com/?q=${event?.location}`}>View on Google Maps</a>
                  : `When there is no location, city ('${event?.city}') will be used instead.`
                }
                secondaryAction={
                  <EventFieldEditButton
                    fieldName='location'
                    editMode={locationEditMode}
                    setEditMode={setLocationEditMode}
                  />
                }
              />
              <Collapse
                unmountOnExit
                in={locationEditMode}
                timeout="auto"
              >
                <TextField
                  fullWidth
                  inputRef={locationInputRef}
                  placeholder='Event location or address'
                  defaultValue={event?.location}
                  type='text'
                  color='secondary'
                />
                <FieldSaveButton onClick={onLocationSave} />
              </Collapse>

              <ListItem
                divider={!respondedEditMode}
                label='Responded'
                value={event.responded}
                secondaryText='Number of responses. Sets the priority of the event.'
                secondaryAction={
                  <EventFieldEditButton
                    fieldName='responses (priority)'
                    editMode={respondedEditMode}
                    setEditMode={setRespondedEditMode}
                  />
                }
              />
              <Collapse
                unmountOnExit
                in={respondedEditMode}
                timeout="auto"
              >
                <TextField
                  fullWidth
                  inputRef={respondedInputRef}
                  placeholder='Number of responses (priority)'
                  defaultValue={event.responded}
                  type='text'
                  color='secondary'
                />
                <FieldSaveButton onClick={onRespondedSave} />
              </Collapse>

              <ListItem
                divider={false}
                label='Ticket link'
                value={event.ticket_link}
                secondaryText={
                  event.ticket_link
                    ? <a target='_blank' rel="noreferrer" href={event.ticket_link}>View ticket</a>
                    : null
                }
              />
            </List>
          </Box>
        </>)
      }
    </Container>
  )
}
export default Event