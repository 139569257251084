import { createTheme } from '@mui/material/styles';

const HEADING_SX = {
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 500,
}

export const theme = createTheme({
  typography: {
    h1: { ...HEADING_SX, marginTop: 0, fontSize: '2.5rem' },
    h2: { ...HEADING_SX, marginTop: '2rem', fontSize: '2rem' },
    h3: HEADING_SX,
    h4: HEADING_SX,
    h5: HEADING_SX,
    h6: HEADING_SX,
  },
  palette: {
    mode: 'dark',
    primary: {
      light: '#a9c7ff',
      main: '#111a29',
      dark: '#275ea7',
    },
    secondary: {
      light: '#d6e3ff',
      main: '#a4c9ff',
      dark: '#00315d',
    },
    background: {
      default: '#111a29',
      paper: '#1e2a47',
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        open: localStorage.getItem('drawerOpen') === '1' ? true : false
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'initial'
          },
          '&.MuiButton-containedSecondary:not(:disabled)': {
            color: '#00315d',
            '&:hover': {
              backgroundColor: '#d6e3ff',
            }
          },
        },
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          padding: '4px 5px 5px',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#a4c9ff',
            color: '#00315d',
            '&:hover': {
              backgroundColor: '#a4c9ff',
            },
            '& svg': {
              color: '#00315d'
            }
          },
        },
      }
    },
  },
});
