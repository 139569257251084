import React, { useState } from 'react'

import {
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

import { useAwsLambdaContext } from './context/AwsLambda'
import { useSnackbar } from './context/Snackbar'

const SearchEventInput = () => {
  const [ query, setQuery ] = useState('')
  const { searchEvent, isSearchEventLoading } = useAwsLambdaContext()
  const { showSnackbar } = useSnackbar()

  const onSubmit = async (e) => {
    e.preventDefault();
    const response = await searchEvent(query)
    if (response?.event) {
      window.location.assign(`/event/${response.event.id}`)
    } else {
      showSnackbar({ severity: 'warning', message: 'Event was not found' })
    }
  }

  return (
    <form onSubmit={onSubmit}>
      {isSearchEventLoading && (
        <Box sx={{ position: 'absolute' }}>
          <CircularProgress />
        </Box>
      )}
      <TextField
        sx={{ maxWidth: 170 }}
        label="Search (id or url)"
        disabled={isSearchEventLoading}
        color="secondary"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Go to event page"
                type='submit'
                disabled={!query}
                edge="end"
                size="small"
                color="secondary"
              >
                <ArrowForward />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  )
}

export default SearchEventInput