import React from 'react'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AddIcon from '@mui/icons-material/Add';

import { logout } from './utils'

const NavMenuList = () => {
  const isCurrent = path => window.location.pathname === path

  return (
    <List disablePadding={true}>
      <ListItemButton href='/' selected={isCurrent('/')}>
        <ListItemIcon>
          <Tooltip title="Dashboard" arrow>
            <BarChartIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={'Dashboard'} />
      </ListItemButton>
      <ListItemButton href='/events' selected={isCurrent('/events')}>
        <ListItemIcon>
          <Tooltip title="Events" arrow>
            <LocalActivityIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={'Events'} />
      </ListItemButton>
      <ListItemButton href='/event/create' selected={isCurrent('/event/create')}>
        <ListItemIcon>
          <Tooltip title="Add new event" arrow>
            <AddIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={'New Event'} />
      </ListItemButton>
      <Divider />
      <ListItemButton onClick={logout} >
        <ListItemIcon>
          <Tooltip title="Logout" arrow>
            <ExitToAppIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={'Logout'} />
      </ListItemButton>
    </List>
  )
}

export default NavMenuList