import React, { useState } from 'react';

import {
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from 'react-router-dom';
import { formatDateTime, getEventImage } from './utils.js';
import { useEvents, useFilterData } from './hooks/index.js';

const Events = () => {
  const [ page, setPage ] = useState(0)
  const { events, totalEvents, isLoading, refresh } = useEvents(page)
  const history = useHistory()
  const { isLoading: loadingFilters, filters } = useFilterData();

  // * TablePagination component is 0-based, while the API is 1-based
  const onPageChange = (_event, newPage) => setPage(newPage)

  return (
    <Container>
      <Typography variant='h1'>All Events</Typography>
      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          maxHeight: '70vh',
          '& .MuiTableCell-head, & .MuiTablePagination-root': {
            backgroundColor: 'primary.dark',
            color: 'white',
          }
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" onClick={refresh}>
                <IconButton aria-label='refresh' color='warning'>
                  <RefreshIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">City</TableCell>
              <TableCell align="center">Source</TableCell>
              <TableCell align="center">Responded</TableCell>
              <TableCell align="center">Date Added</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <TableRow
                key={`event-${event.id}`}
                className="clickable-row"
                onClick={(e) => {
                  // * Prevent navigation when clicking on a link inside the row
                  if (e.nativeEvent.target !== e.nativeEvent.target.parentElement.querySelector('td > a')) {
                    history.push(`/event/${event.id}`)
                  }
                }}
              >
                <TableCell className='thumbnail-cell' padding="none">
                  <img
                    height={70}
                    src={getEventImage(event?.image_url)}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                </TableCell>
                <TableCell align="center">{event.id}</TableCell>
                <TableCell align="left">{event.title}</TableCell>
                <TableCell align="center">{formatDateTime(event.date)}</TableCell>
                <TableCell align="center">{event.city}</TableCell>
                {/* <TableCell align="center">
                  {event.categories.map((category) => (<Chip key={`${event.id}-${category}`} label={category} />))}
                </TableCell> */}
                <TableCell align="center">
                  <a target='_blank' rel="noreferrer" href={event.source_url}>Source</a>
                </TableCell>
                <TableCell align="center">{event.responded}</TableCell>
                <TableCell align="center">{formatDateTime(event.date_added)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isLoading && <CircularProgress />}
        <TablePagination
          component="div"
          sx={{ position: 'sticky', bottom: 0 }}
          count={totalEvents}
          rowsPerPage={events?.length}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={onPageChange}
        />
      </TableContainer>
    </Container>
  )
}
export default Events;