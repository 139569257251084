import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Lambda } from "aws-sdk";
import AWS from 'aws-sdk';

import {
  REGION,
  USER_POOL_ID,
  IDENTITY_POOL_ID,
  LAMBDA_NAME,
  PUBLIC_LAMBDA_API_URL,
} from './../constants.js';
import { useSnackbar } from "./Snackbar.jsx";
import { generateEventImages } from "../utils.js";

const AwsLambdaContext = createContext();

async function callLambda(client, payload) {
  const params = {
    FunctionName: LAMBDA_NAME,
    InvocationType: 'RequestResponse',
    LogType: 'None',
    Payload: JSON.stringify({ body: payload }),
  };

  return new Promise((resolve) => {
    client.invoke(params, function(err, data) {
      const response = data?.Payload ? JSON.parse(data?.Payload) : null
      if (response?.statusCode === 200) {
        resolve([ err || response.errorMessage, response.body ])
      } else {
        resolve([ response?.errorMessage || response?.body || err, response?.body ])
      }
    });
  });
}

export const AwsLambdaProvider = ({ children }) => {
  const [ client, setClient ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSearchEventLoading, setIsSearchEventLoading ] = useState(false)
  const [ isStatsLoading, setIsStatsLoading ] = useState(false)
  const { showSnackbar } = useSnackbar();

  const handleCredentialsError = (error) => {
    if (!error) {
      return
    }
    if (error?.code === 'CredentialsError' || error?.code === 'NotAuthorizedException') {
      sessionStorage.removeItem("jwt");
      window.location.reload();
    }
  }

  const initClient = () => {
    const jwt = sessionStorage.getItem("jwt");
    if (!jwt) {
      return;
    }

    setIsLoading(true);
    try {
      AWS.config.region = REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins: { [`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`]: sessionStorage.getItem("jwt") }
      });

      // Make the call to obtain credentials
      AWS.config.credentials.get(function(error) {
        handleCredentialsError(error)

        const _client = new Lambda({
          region: REGION,
          credentials: {
            accessKeyId: AWS.config.credentials.accessKeyId,
            secretAccessKey: AWS.config.credentials.secretAccessKey,
            sessionToken: AWS.config.credentials.sessionToken
          }
        });

        setClient(_client);
        setIsLoading(false);
      });
    } catch (err) {
      console.log("🚀 ~ initClient ~ err:", err)
      setIsLoading(false);
    }
  }

  const getFilterData = useCallback(async () => {
    if (!client) {
      return
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${PUBLIC_LAMBDA_API_URL}/filters`, { method: 'POST' })
      if (response?.status !== 200) {
        throw new Error(response)
      }
      const { filters } = await response.json()
      setIsLoading(false);
      return filters
    } catch (error) {
      console.log("🚀 ~ getFilterData ~ error:", error)
      showSnackbar({ severity: 'error', message: 'Failed to get filter data' })
      setIsLoading(false);
      return null
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const getStats = useCallback(async () => {
    if (!client) {
      return
    }

    setIsStatsLoading(true);
    const [ err, response ] = await callLambda(client, { path: '/events/stats' })
    setIsStatsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: response?.message || err?.message || 'Failed to get stats' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const getEvent = useCallback(async eventId => {
    if (!client || !eventId) {
      return
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${PUBLIC_LAMBDA_API_URL}/event`, {
        method: 'POST',
        body: JSON.stringify({ id: eventId }),
      })
      if (response?.status !== 200) {
        throw new Error(response)
      }
      const data = await response.json()
      setIsLoading(false);
      return data
    } catch (error) {
      console.log("🚀 ~ getEvent ~ error:", error)
      showSnackbar({ severity: 'error', message: `Failed to get event data with ID: ${eventId}` })
      setIsLoading(false);
      return null
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const updateEvent = useCallback(async (options = {}) => {
    if (!client) {
      return
    }

    setIsLoading(true);
    const { id, updateData } = options
    const [ err, response ] = await callLambda(client, { path: '/event/update', id, updateData })
    setIsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: response?.message || err?.message || 'Failed to update event' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      showSnackbar({ severity: 'success', message: response?.message || 'Event updated successfully' })
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const searchEvent = useCallback(async query => {
    if (!client) {
      return
    }

    setIsSearchEventLoading(true);
    const [ err, response ] = await callLambda(client, { path: '/event/search', query })
    setIsSearchEventLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: response?.message || err?.message || 'Failed to get event' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      // showSnackbar({ severity: 'success', message: response?.message || 'Event retrieved successfully' })
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const createEvent = useCallback(async (data, imageFile) => {
    if (!client) {
      return
    }

    if (!data?.length) {
      showSnackbar({ severity: 'error', message: 'No data provided to create event' })
      return null
    }

    setIsLoading(true);

    if (imageFile) {
      const images = await generateEventImages(imageFile)
      data.push({ name: 'images', value: images })
    }

    const [ err, response ] = await callLambda(client, {
      path: '/event/create',
      newEvent: data.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})
    })

    setIsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: response?.message || err?.message || 'Failed to create event' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      showSnackbar({ severity: 'success', message: response?.message || 'Event created successfully' })
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const getEvents = useCallback(async (options = {}) => {
    if (!client) {
      return
    }

    setIsLoading(true);
    const { categories, cities, page } = options
    const [ err, response ] = await callLambda(client, {
      path: '/events',
      categories,
      cities,
      page
    })
    setIsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: 'Failed to get events' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  // get email subscriptions from MailChimp
  const getSubscriptions = useCallback(async () => {
    if (!client) {
      return
    }

    setIsLoading(true);
    const [ err, response ] = await callLambda(client, { path: '/subscriptions' })
    setIsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: 'Failed to get subscriptions' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const handleNewEventImage = useCallback(async (eventId, file) => {
    if (!client) {
      return
    }

    setIsLoading(true);
    const images = await generateEventImages(file)
    // send data URIs (in base64) to Lambda and upload to S3 from there
    const [ err, response ] = await callLambda(client, {
      path: '/event/update',
      id: eventId,
      updateData: { images }
    })
    setIsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: 'Failed to update event image' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      showSnackbar({ severity: 'success', message: 'Successfully updated event image!' })
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  const deleteEvent = useCallback(async eventId => {
    if (!client) {
      return
    }

    setIsLoading(true);
    // send data URIs (in base64) to Lambda and upload to S3 from there
    const [ err, response ] = await callLambda(client, { path: '/event/delete', id: eventId })
    setIsLoading(false);

    if (err) {
      showSnackbar({ severity: 'error', message: 'Failed to delete event.' })
      console.log(err);
      handleCredentialsError(err)
      return null
    } else {
      showSnackbar({ severity: 'success', message: 'Successfully deleted event!' })
      return response
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])

  useEffect(() => {
    initClient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AwsLambdaContext.Provider value={{
      isLoading,
      isSearchEventLoading,
      isStatsLoading,
      initClient,
      client,
      getEvent,
      searchEvent,
      updateEvent,
      createEvent,
      deleteEvent,
      getEvents,
      getFilterData,
      getStats,
      getSubscriptions,
      handleNewEventImage,
    }}>
      {children}
    </AwsLambdaContext.Provider>
  );
}

export const useAwsLambdaContext = () => {
  return useContext(AwsLambdaContext);
};