import { Snackbar } from '@mui/material';
import React, { Suspense, createContext, lazy, useContext, useState } from 'react';

const Alert = lazy(() => import('@mui/material/Alert'));

const DEFAULT_AUTO_HIDE_DURATION = 5000;
const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [ message, setMessage ] = useState(null);

  const showSnackbar = ({
    severity,
    message,
    autoHideDuration
  }) => setMessage({ severity, message, autoHideDuration });

  const props = {
    autoHideDuration: message?.autoHideDuration || DEFAULT_AUTO_HIDE_DURATION,
    message: !message?.severity ? message?.message : null,
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setMessage(null)}
        open={Boolean(message)}
        {...props}
      >
        <span>
          {message?.severity &&
            <Suspense fallback={<p>Loading...</p>}>
              <Alert onClose={() => setMessage(null)} severity={message.severity} variant="filled">
                {message.message}
              </Alert>
            </Suspense>
          }
        </span>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}